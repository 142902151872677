// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import Vue from 'vue/dist/vue.esm'
// import Vuex from 'vuex'

// import MatestackUiCore from 'matestack-ui-core'

// let matestackUiApp = undefined

// document.addEventListener('DOMContentLoaded', () => {
//   matestackUiApp = new Vue({
//     el: "#matestack-ui",
//     store: MatestackUiCore.store
//   })
// })

import { createApp } from 'vue'
import MatestackUiVueJs from 'matestack-ui-vuejs'
import MatestackUiBootstrap from "matestack-ui-bootstrap"

//Bootstrap
import * as bootstrap from 'bootstrap'
// import { Tooltip, Toast, Popover, Alert } from 'bootstrap'

// CSS
import 'css/application'

// Datepicker
import Flatpickr from 'flatpickr'
import 'js/datepicker'
require("flatpickr/dist/themes/dark.css");

// Custom Components
import paypalCheckout from "../../matestack/booking/components/paypal_checkout.js"
import editPlayersForm from "../../matestack/booking/components/form/edit_players_form.js"

const appInstance = createApp({})

appInstance.component('paypal-checkout', paypalCheckout)
appInstance.component('edit-players-form', editPlayersForm)


document.addEventListener('DOMContentLoaded', () => {
  MatestackUiVueJs.mount(appInstance)
})

// import MatestackUiBootstrap from "matestack-ui-bootstrap"

// //Bootstrap
// import * as bootstrap from 'bootstrap'
// // import { Tooltip, Toast, Popover, Alert } from 'bootstrap'

// // CSS
// import 'css/application'

// // Datepicker
// import Flatpickr from 'flatpickr'
// import 'js/datepicker'
// require("flatpickr/dist/themes/dark.css");

// // Paypal checkout component
// import "../../matestack/booking/components/paypal_checkout.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Initialization
Rails.start()
ActiveStorage.start()

//Tooltip Init (fixing as described here: https://github.com/twbs/bootstrap/issues/32372#issuecomment-770098159)

// on page load
document.addEventListener('DOMContentLoaded', () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, { animation: false })
  })
});

// on matestack page transition
MatestackUiVueJs.eventHub.$on("page_loaded", () => {
  setTimeout(function () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, { animation: false })
    })
  }, 500);
});
