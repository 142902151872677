// import Vue from 'vue/dist/vue.esm';
// import Vuex from 'vuex';
import axios from 'axios';

import MatestackUiVueJs from 'matestack-ui-vuejs';

const editPlayersForm = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,
  data() {
    return {
      convertToNonExclusive: false,
    };
  },
  methods: {
    blah: function() {
      this.convertToNonExclusive = this
          .getTemplateElement()
          .querySelector('#convert_to_non_exclusive_1')
          .checked;
    }
  },
  mounted(){
  },
};

export default editPlayersForm;
