import axios from 'axios';
import MatestackUiVueJs from 'matestack-ui-vuejs';

var German = {
    weekdays: {
        shorthand: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        longhand: [
            "Sonntag",
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag",
        ]
    },
    months: {
        shorthand: [
            "Jan",
            "Feb",
            "Mär",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez",
        ],
        longhand: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
        ]
    },
    firstDayOfWeek: 1,
    weekAbbreviation: "KW",
    rangeSeparator: " bis ",
    scrollTitle: "Zum Ändern scrollen",
    toggleTitle: "Zum Umschalten klicken"
};

document.addEventListener('DOMContentLoaded', function() {
  initialize_flatpickr();
})

MatestackUiVueJs.eventHub.$on('page_loaded', function(url){
  setTimeout(initialize_flatpickr, 10);
})

var datepicker;

function initialize_flatpickr(){
  flatpickr.l10ns.de = German
  flatpickr.localize(flatpickr.l10ns.de);
  datepicker = flatpickr('.flatpickr', {
    minDate: 'today',
    dateFormat: "d.m.Y",
    inline: true,
    disable: [
      function(date) {
          // return true to disable
          return (date.getDay() === 0 || date.getDay() === 6);
      }
    ],
    onReady: function(selectedDates, dateStr, instance) {
      updateDatepicker(instance)
    },
    onMonthChange: function(selectedDates, dateStr, instance) {
      updateDatepicker(instance)
    }
  });
  var dom_picker = document.querySelector('.flatpickr')
  if(dom_picker) {
    setTimeout(function(){
      var init_value = dom_picker.getAttribute('init-value')
      datepicker.setDate(init_value + "", true, "d.m.Y")
    }, 100);
  }
}

function updateDatepicker(instance){
  axios.get('available_dates?month=' + (instance.currentMonth + 1) + '&year=' + instance.currentYear).then(function(response){
    var dates = response.data;
    datepicker.set('disable', dates);
  });
}
