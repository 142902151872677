// import Vue from 'vue/dist/vue.esm';
// import Vuex from 'vuex';
import axios from 'axios';

import MatestackUiVueJs from 'matestack-ui-vuejs';

const paypalCheckout = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,
  data() {
    return {
      loading: false
    };
  },
  inject: [
    'appNavigateTo'
  ],
  methods: {

  },
  mounted(){
    var self = this;
    var amount = self.props["amount"]
    MatestackUiVueJs.eventHub.$emit("paypal_loaded");
    paypal.Buttons({
      env: self.props["env"], // Valid values are sandbox and live.
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount // Can also reference a variable or function
            }
          }]
        });
      },
      onApprove: (data, actions) => {
        return actions.order.capture().then(async function(orderData) {
          // Successful capture! For dev/demo purposes:
          // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          // console.log(orderData.id);
          // const transaction = orderData.purchase_units[0].payments.captures[0];
          // alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
          // When ready to go live, remove the alert and show a success message within this page. For example:
          // const element = document.getElementById('paypal-button-container');
          // element.innerHTML = '<h3>Thank you for your payment!</h3>';
          // Or go to another URL:  actions.redirect('thank_you.html');
          self.loading = true
          const response = await fetch(self.props["confirm_path"], {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.getElementsByName("csrf-token")[0].getAttribute('content')
            },
            body: JSON.stringify({order_id: orderData.id})
          });
          const responseData = await response.json();
          if (responseData.status === 'COMPLETED') {
            // REDIRECT TO SUCCESS PAGE
            MatestackUiVueJs.eventHub.$emit("page_loading_triggered");
            self.appNavigateTo({ url: self.props["success_path"], backwards: false });
          }
        });
      }
    }).render('#paypal-button-container');
  }
};

export default paypalCheckout;
